export interface IntegrationConnection {
  region: string;
  regionCode: string;
  country: string;
  marketplaceId: string;
  countryCode: string;
  marketplaceUrl: string;
}

export const MARKETPLACE_DATA: IntegrationConnection[] = [
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Canada',
    marketplaceId: 'A2EUQ1WTGCTBG2',
    countryCode: 'CA',
    marketplaceUrl: 'https://www.amazon.ca',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'United States of America',
    marketplaceId: 'ATVPDKIKX0DER',
    countryCode: 'US',
    marketplaceUrl: 'https://www.amazon.com',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Mexico',
    marketplaceId: 'A1AM78C64UM0Y8',
    countryCode: 'MX',
    marketplaceUrl: 'https://www.amazon.com.mx',
  },
  {
    region: 'North America',
    regionCode: 'NA',
    country: 'Brazil',
    marketplaceId: 'A2Q3Y263D00KWC',
    countryCode: 'BR',
    marketplaceUrl: 'https://www.amazon.com.br',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Spain',
    marketplaceId: 'A1RKKUPIHCS9HS',
    countryCode: 'ES',
    marketplaceUrl: 'https://www.amazon.es',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'United Kingdom',
    marketplaceId: 'A1F83G8C2ARO7P',
    countryCode: 'UK',
    marketplaceUrl: 'https://www.amazon.co.uk',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'France',
    marketplaceId: 'A13V1IB3VIYZZH',
    countryCode: 'FR',
    marketplaceUrl: 'https://www.amazon.fr',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Belgium',
    marketplaceId: 'AMEN7PMS3EDWL',
    countryCode: 'BE',
    marketplaceUrl: 'https://www.amazon.com.be',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Netherlands',
    marketplaceId: 'A1805IZSGTT6HS',
    countryCode: 'NL',
    marketplaceUrl: 'https://www.amazon.nl',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Germany',
    marketplaceId: 'A1PA6795UKMFR9',
    countryCode: 'DE',
    marketplaceUrl: 'https://www.amazon.de',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Italy',
    marketplaceId: 'APJ6JRA9NG5V4',
    countryCode: 'IT',
    marketplaceUrl: 'https://www.amazon.it',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Sweden',
    marketplaceId: 'A2NODRKZP88ZB9',
    countryCode: 'SE',
    marketplaceUrl: 'https://www.amazon.se',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'South Africa',
    marketplaceId: 'AE08WJ6YKNBMC',
    countryCode: 'ZA',
    marketplaceUrl: 'https://www.amazon.com.za',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Poland',
    marketplaceId: 'A1C3SOZRARQ6R3',
    countryCode: 'PL',
    marketplaceUrl: 'https://www.amazon.pl',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Egypt',
    marketplaceId: 'ARBP9OOSHTCHU',
    countryCode: 'EG',
    marketplaceUrl: 'https://www.amazon.eg',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Turkey',
    marketplaceId: 'A33AVAJ2PDY3EV',
    countryCode: 'TR',
    marketplaceUrl: 'https://www.amazon.com.tr',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'Saudi Arabia',
    marketplaceId: 'A17E79C6D8DWNP',
    countryCode: 'SA',
    marketplaceUrl: 'https://www.amazon.sa',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'United Arab Emirates',
    marketplaceId: 'A2VIGQ35RCS4UG',
    countryCode: 'AE',
    marketplaceUrl: 'https://www.amazon.ae',
  },
  {
    region: 'Europe',
    regionCode: 'EU',
    country: 'India',
    marketplaceId: 'A21TJRUUN4KGV',
    countryCode: 'IN',
    marketplaceUrl: 'https://www.amazon.in',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Singapore',
    marketplaceId: 'A19VAU5U5O7RUS',
    countryCode: 'SG',
    marketplaceUrl: 'https://www.amazon.sg',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Australia',
    marketplaceId: 'A39IBJ37TRP1C6',
    countryCode: 'AU',
    marketplaceUrl: 'https://www.amazon.com.au',
  },
  {
    region: 'Far East',
    regionCode: 'FE',
    country: 'Japan',
    marketplaceId: 'A1VC38T7YXB528',
    countryCode: 'JP',
    marketplaceUrl: 'https://www.amazon.co.jp',
  },
];
export const getMarketplace = (marketplaceId: string) => {
  const marketplace = MARKETPLACE_DATA.find(
    (marketplace) => marketplace.marketplaceId === marketplaceId,
  );
  return marketplace || MARKETPLACE_DATA.find((marketplace) => marketplace.countryCode === 'US');
};

export const carrierMapping = [
  {
    name: 'FedEx',
    value: 'FEDERAL_EXPRESS_CORP',
  },
  {
    name: 'UPS',
    value: 'UNITED_PARCEL_SERVICE_INC',
  },
  {
    name: 'USPS',
    value: 'UNITED_STATES_POSTAL_SERVICE',
  },
  {
    name: 'DHL',
    value: 'DHL_EXPRESS_USA_INC',
  },
  {
    name: 'Other',
    value: 'OTHER',
  },
];
