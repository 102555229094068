import React, { useEffect } from 'react';
import { getMarketplace } from '../../utils/ConnectionConfig';
import DynamicTable, { Column } from '../common/DynamicTable';
import { Product } from '../common/types';

interface ReceivedProductsTableProps {
  order: {
    products: Product[];
  };
  receivedProducts: (Product & { receivedQuantity: string })[];
  setReceivedProducts: React.Dispatch<React.SetStateAction<Product[]>>;
}

const ReceivedProductsTable: React.FC<ReceivedProductsTableProps> = ({
  order,
  receivedProducts,
  setReceivedProducts,
}) => {
  useEffect(() => {
    setReceivedProducts(() => {
      return order.products.map((product) => {
        return {
          ...product,
          receivedQuantity: 0,
        };
      });
    });
  }, []);
  const handleQuantityChange = (listingId: string, value: string) => {
    setReceivedProducts(
      receivedProducts.map((rProduct) => {
        if (rProduct.listingId === listingId) {
          return { ...rProduct, receivedQuantity: parseInt(value) };
        }
        return rProduct;
      }),
    );
  };

  const columns: Column<Product>[] = [
    {
      header: ' ',
      accessor: (product) =>
        product.images && product.images.length > 0 ? (
          <img
            className='rounded-md object-scale-down h-20'
            src={product.images[0]}
            height={100}
            width={100}
            alt={product.name}
          />
        ) : null,
      headerClassnamePerColumn:
        'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      header: 'Product Name & ASIN',
      accessor: (product) => (
        <div className='max-w-48 line-clamp-3 '>
          {product?.name}
          <br />
        </div>
      ),
    },
    {
      header: 'Identifiers',
      accessor: (product) => (
        <>
          <a
            href={`${
              getMarketplace(product.marketplaceData?.marketplaceId).marketplaceUrl
            }/dp/${product.marketplaceData?.asin}`}
            target='_blank'
            rel='noopener noreferrer'
            className='hover:underline'
          >
            <span className='font-bold'>ASIN: </span>
            {product.marketplaceData?.asin}
          </a>
          <br />

          <span className='font-bold'>FNSKU: </span>
          {product.marketplaceData?.fnSku}
          <br />
          <span className='font-bold'>Seller SKU: </span>
          {product.marketplaceData?.sellerSku}
        </>
      ),
    },
    {
      header: 'Expected Qty.',
      accessor: (product) => (
        <div className='px-5 py-1 text-green-800 bg-green-200 w-fit rounded-full text-center mx-auto'>
          {product?.expectedQuantity || 0}
        </div>
      ),
    },
    {
      header: 'Actual Qty.',
      accessor: (product) => {
        const receivedProduct = receivedProducts.find(
          (rProduct) => rProduct.listingId === product.listingId,
        );
        return (
          <input
            type='number'
            value={receivedProduct?.receivedQuantity || ''}
            onChange={(e) => handleQuantityChange(product.listingId, e.target.value)}
            className='block w-[100px] rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
          />
        );
      },
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={order?.products || []}
      headerClassname='bg-gray-50 !p-3.5'
      rowsClassname='h-20'
    />
  );
};

export default ReceivedProductsTable;
