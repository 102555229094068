import { useUser } from '@clerk/clerk-react';
import { BellIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BaseContext from '../components/common/BaseContext';
import Stepper from '../components/Dashboard/Stepper';
import useFetch from '../hooks/useFetch';
import useRequest from '../hooks/useRequest';

enum StepCompletionState {
  INITIAL = 0,
  COMPLETED = 1,
  INCOMPLETE = 2,
}

function GetStarted() {
  const { user } = useUser();
  const { organization } = useContext(BaseContext);
  const navigate = useNavigate();

  // States
  const [isStepsCompleted, setIsStepsCompleted] = useState<StepCompletionState>(
    StepCompletionState.INITIAL,
  );
  const authenticatedFetch = useFetch();
  const [hasWarehouse, setHasWarehouse] = useState(false);
  const [hasClient, setHasClient] = useState(false);
  const [hasIntegration, setHasIntegration] = useState(false);
  const [hasOrder, setHasOrder] = useState(false);
  const [hasInventory, setHasInventory] = useState(false);
  const { setLoading, setOrgRole } = useContext(BaseContext);

  const { executeRequest: fetchUser } = useRequest('/api/v1/users/me');

  useEffect(() => {
    const checkStepsCompleted = async () => {
      if (organization) {
        setLoading(true);
        const [warehousesData, merchantsData, inventoryData, ordersData] = await Promise.all([
          authenticatedFetch(
            '/api/v1/warehouses?' +
              new URLSearchParams({
                'filter[organizationId]': organization?.organizationId,
              }),
          ),
          authenticatedFetch(
            '/api/v1/merchants?' +
              new URLSearchParams({
                'filter[organizationId]': organization.organizationId,
              }),
          ),
          authenticatedFetch(
            `/api/v1/inventory?` +
              new URLSearchParams({
                'filter[organizationId]': organization?.organizationId,
              }),
          ),
          authenticatedFetch(
            `/api/v1/outbound-orders?` +
              new URLSearchParams({
                'filter[organizationId]': organization?.organizationId,
              }),
          ),
        ]);

        const warehouseExists = warehousesData.success && warehousesData.data.length > 0;
        const merchantsExist = merchantsData.success && merchantsData.data.length > 0;
        const inventoryExists = inventoryData.success && inventoryData.data.length > 0;
        const ordersExist = ordersData.success && ordersData.data.length > 0;

        setHasWarehouse(warehouseExists);
        setHasClient(merchantsExist);
        setHasInventory(inventoryExists);
        setHasOrder(ordersExist);
        let integrationsExist: boolean;

        if (merchantsExist) {
          const integrationsData = await authenticatedFetch(
            '/api/v1/integrations?' +
              new URLSearchParams({
                'filter[merchantId]': merchantsData.data
                  .map((merchant) => merchant.merchantId)
                  .join(','),
              }),
          );
          integrationsExist = integrationsData.success && integrationsData.data.length > 0;
          setHasIntegration(integrationsExist);
        } else {
          setHasIntegration(false);
        }
        if (
          warehouseExists &&
          merchantsExist &&
          inventoryExists &&
          ordersExist &&
          integrationsExist
        ) {
          setIsStepsCompleted(StepCompletionState.COMPLETED);
          localStorage.setItem('stepsCompleted', '1');
        } else {
          setIsStepsCompleted(StepCompletionState.INCOMPLETE);
        }
        setLoading(false);
      }
    };
    (async () => {
      // setLoading(true)
      await checkStepsCompleted()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // setLoading(false)
        });
    })();
  }, [organization]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (organization) {
        const userResponse = await fetchUser({
          queryParams: {},
          urlParams: {},
        });
        if (userResponse.success)
          setOrgRole(Object.keys(userResponse.data.role[organization?.organizationId])[0] as any);
      }
    })();
  }, [organization]);

  useEffect(() => {
    if (isStepsCompleted == StepCompletionState.COMPLETED) navigate('dashboard');
  }, [isStepsCompleted]);

  return (
    <div>
      {isStepsCompleted === StepCompletionState.INCOMPLETE ? (
        <div className='p-8'>
          <div className='flex justify-between'>
            <h1 className='my-8 text-[16px]'>
              Hey {user?.firstName || user?.primaryEmailAddress?.emailAddress}, welcome to Ignite
              Fulfill. Please complete the following steps to get started!{' '}
            </h1>
            <Link to={`/${organization?.organizationId}/notifications`}>
              <BellIcon className='text-gray-500 h-8 w-8' />
            </Link>
          </div>
          <Stepper
            hasOrder={hasOrder}
            hasWarehouse={hasWarehouse}
            hasClient={hasClient}
            hasInventory={hasInventory}
            hasIntegration={hasIntegration}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default GetStarted;
