import React, { useState } from 'react';

export interface Filter {
  id: string;
  component: React.ReactNode;
}

interface FilterPanelProps {
  children: any;
  onApplyFilters: (filters: Filter[]) => void;
  onCancel: () => void;
  handleResetFilters: () => void;
  classNames?: {
    panel?: string;
    header?: string;
    title?: string;
    clearButton?: string;
    resetButton?: string;
    filterItem?: string;
    cancelButton?: string;
    confirmButton?: string;
  };
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  children,
  onApplyFilters,
  handleResetFilters,
  onCancel,
  classNames = {},
}) => {
  const [filterValues] = useState<Filter[]>(children);

  const handleApplyFilters = () => {
    onApplyFilters(filterValues);
  };

  return (
    <div className={`bg-white p-4 rounded-lg shadow-lg ${classNames.panel}`}>
      <div className={`flex justify-between items-center mb-4 ${classNames.header}`}>
        <h2 className={`text-lg font-bold ${classNames.title}`}>Filters</h2>
        <div>
          <button
            className={`text-red-500 hover:underline ${classNames.resetButton}`}
            onClick={handleResetFilters}
          >
            Reset Filters
          </button>
        </div>
      </div>
      {children}
      <div className='flex justify-center gap-5 mt-5'>
        <button
          className={`px-4 py-1 text-hopstack-blue-700 bg-white border border-hopstack-blue-700/25 rounded-lg hover:bg-hopstack-blue-100 mr-2 ${classNames.cancelButton}`}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          className={`px-4 py-1 text-white bg-hopstack-blue-700 rounded-lg hover:bg-hopstack-blue-700/90 ${classNames.confirmButton}`}
          onClick={handleApplyFilters}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default FilterPanel;
