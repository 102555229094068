import React, { useContext, useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import SlideOver from '../common/SlideOver';
import useRequest from '../../hooks/useRequest';
import { apiPaths } from '../../utils/ApiPaths';
import { Integration } from '../common/types';
import BaseContext from '../common/BaseContext';
import { IntegrationConnection } from '../../utils/ConnectionConfig';
import { CONNECTION_STATUS } from '../../pages/ConnectionDetails';
import Dropdown from '../common/Dropdown';

interface Marketplace extends IntegrationConnection {
  id: number;
  name: string;
}

interface AddNewConnectionProps {
  isOpen: boolean;
  onClose: () => void;
  marketplaces: Marketplace[];
  integration: Integration;
}

const AddNewConnection: React.FC<AddNewConnectionProps> = ({
  isOpen,
  onClose,
  marketplaces,
  integration,
}) => {
  const { setLoading } = useContext(BaseContext);
  const [selectedMarketplace, setSelectedMarketplace] = useState<Marketplace | null>(null);
  const [nickname, setNickname] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const { executeRequest: addConnection } = useRequest<Integration>(
    apiPaths.GET_INTEGRATIONS + '/connection',
  );

  useEffect(() => {
    if (marketplaces.length > 0) {
      setSelectedMarketplace(marketplaces[0]);
    }
  }, [marketplaces]);

  const validateForm = (): boolean => {
    if (!selectedMarketplace) {
      setError('Please select a marketplace');
      return false;
    }
    if (!nickname.trim()) {
      setError('Please enter a nickname for the marketplace');
      return false;
    }
    setError(null);
    return true;
  };

  const handleAddConnections = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      await addConnection(
        {
          queryParams: {},
          urlParams: {},
        },
        {
          method: 'POST',
          body: JSON.stringify({
            integrationId: integration.integrationId,
            connectionName: selectedMarketplace.countryCode,
            nickname,
            status: CONNECTION_STATUS.ENABLED,
            details: {
              marketplaceId: selectedMarketplace.marketplaceId,
            },
          }),
        },
      );
      onClose();
    } catch (error) {
      setError('Failed to add connection. Please try again.');
      console.error('Error adding connection:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSelectedMarketplace(null);
    setNickname('');
    setError(null);

    onClose();
  };

  return (
    <SlideOver open={isOpen} setOpen={handleClose}>
      <div className='h-full flex flex-col'>
        <div className='flex justify-between items-center px-4 border-gray-200 relative'>
          <h2 className='text-lg font-medium text-gray-900'>Add Additional Marketplace</h2>
          <button
            onClick={handleClose}
            className='text-gray-400 hover:text-gray-500 absolute right-0 top-0 -mt-6'
            aria-label='Close'
          >
            <XMarkIcon className='h-6 w-6' strokeWidth={120} />
          </button>
        </div>

        <div className='flex-1 overflow-y-auto px-4'>
          <p className='text-sm text-gray-500 mb-4'>
            This will add additional marketplaces for the same connection. Please note that
            marketplaces will be added only if they are enabled and fully registered on Amazon.{' '}
            <a
              href='https://help.ignitefulfill.com/home/enabling-additional-marketplaces'
              target={'blank'}
              className='text-hopstack-blue-600 hover:underline'
            >
              Learn More
            </a>
          </p>

          <div className='space-y-6'>
            <div>
              <label htmlFor='marketplace' className='block text-sm font-medium text-gray-700 mb-1'>
                Marketplace
              </label>
              {/* <Listbox value={selectedMarketplace} onChange={setSelectedMarketplace}>
                <div className='relative mt-1'>
                  <Listbox.Button className='relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-hopstack-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm'>
                    <span className='block truncate'>
                      {selectedMarketplace?.name || 'Select Marketplace'}
                    </span>
                    <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                      <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                    </span>
                  </Listbox.Button>
                  <Transition
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                      {marketplaces.map((marketplace) => (
                        <Listbox.Option
                          key={marketplace.id}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-hopstack-blue-100 text-hopstack-blue-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={marketplace}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? 'font-medium' : 'font-normal'
                                }`}
                              >
                                {marketplace?.name || 'N/A'}
                              </span>
                              {selected ? (
                                <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-hopstack-blue-600'>
                                  <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox> */}
              <Dropdown<Marketplace>
                items={marketplaces}
                selectedItem={selectedMarketplace}
                setSelectedItem={setSelectedMarketplace}
                getItemId={(item) => item.id}
                getItemDisplayValue={(item) => item.name}
                placeholder='Select a marketplace'
              />
            </div>

            <div>
              <label htmlFor='nickname' className='block text-sm font-medium text-gray-700 mb-1'>
                Nickname for the Marketplace
              </label>
              <input
                type='text'
                id='nickname'
                value={nickname}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNickname(e.target.value)}
                placeholder='Marketplace 1'
                className='mt-1 block w-full border p-2 rounded-md border-gray-300 shadow-sm focus:outline-0'
              />
            </div>

            {error && <p className='text-red-500 text-sm'>{error}</p>}
          </div>
        </div>

        <div className='flex justify-between items-center px-4 py-4 border-gray-200'>
          <button onClick={handleClose} className='text-hopstack-blue-600 hover:underline'>
            Go back
          </button>
          <button
            type='button'
            className='inline-flex justify-center rounded-md border border-transparent bg-hopstack-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-hopstack-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-hopstack-blue-500 focus-visible:ring-offset-2'
            onClick={handleAddConnections}
          >
            Continue
            <ChevronRightIcon className='ml-2 h-4 w-4' />
          </button>
        </div>
      </div>
    </SlideOver>
  );
};

export default AddNewConnection;
