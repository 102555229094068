import { EllipsisVerticalIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Warehouse, InventoryData, MarketplaceData } from '../components/common/types';
import BaseContext from '../components/common/BaseContext';
import { Menu } from '@headlessui/react';
import Modal from '../components/common/Modal';
import { useParams } from 'react-router-dom';
import SlideOver from '../components/common/SlideOver';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import Button from '../components/common/Button';
import UploadFile from '../components/Inventory/UploadFile';
import MapFields from '../components/Inventory/MapFields';
import Confirmation from '../components/Inventory/Confirmation';
import PastUploads from '../components/Inventory/PastUploads';
import UploadDetails from '../components/Inventory/UploadDetails';
import useFetch from '../hooks/useFetch';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from '../components/common/Spinner';
import InventoryFilter from '../components/Inventory/InventoryFilters';
import { getMarketplace, MARKETPLACE_DATA } from '../utils/ConnectionConfig';

export enum UploadStage {
  UploadFile = 'uploadFile',
  MapFields = 'mapFields',
  Confirmation = 'confirmation',
}

// eslint-disable-next-line react/prop-types
export const CrossSVG = ({ width, height, color }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color}
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='18' y1='6' x2='6' y2='18'></line>
    <line x1='6' y1='6' x2='18' y2='18'></line>
  </svg>
);

export const ColumnHeader = [
  {
    name: 'SKU',
    accessor: 'sku',
  },
  {
    name: 'Client Name',
    accessor: 'merchantName',
  },
  {
    name: 'Quantity',
    accessor: 'quantity',
  },
];

const Inventory = () => {
  const authenticatedFetch = useFetch();

  const [products, setProducts] = useState<InventoryData[]>([]);
  const { setShowNotification, organization, setOrganization } = useContext(BaseContext);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [warehouse, setWarehouse] = useState<Warehouse>();
  const [location, setLocation] = useState<any>();
  const [damagedLocation, setDamagedLocation] = useState<any>();
  const [quantity, setQuantity] = useState(0);
  const [reason, setReason] = useState('');
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [marketplaces, setMarketplaces] = useState<MarketplaceData>();
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);

  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [merchants, setMerchants] = useState([]);
  const [openNewUploadSlideOver, setOpenNewUploadSlideOver] = useState<boolean>(false);
  const [openPastUploadsSlideOver, setOpenPastUploadsSlideOver] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStage, setUploadStage] = useState<UploadStage | null>(null);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [mappedFields, setMappedFields] = useState({});
  const [fileName, setFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [selectedUpload, setSelectedUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [integration, setIntegration] = useState(null);
  const [s3Url, setS3Url] = useState<string>();

  const currentPageRef = useRef(1);

  const params = useParams();

  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { executeRequest: fetchIntegrationsData } = useRequest(apiPaths.GET_INTEGRATIONS, []);
  const { executeRequest: fetchWarehouseLocations } = useRequest(
    apiPaths.GET_WAREHOUSE_LOCATIONS,
    [],
  );
  const {
    executeRequest: fetchInventoryCall,
    isRequestExecuted: inventoryCallMade,
    pending: inventoryLoading,
  } = useRequest(apiPaths.GET_INVENTORY, []);
  const { executeRequest: fetchWarehousesCall } = useRequest(apiPaths.GET_WAREHOUSES, []);
  const { executeRequest: fetchMerchantsCall } = useRequest(apiPaths.GET_MERCHANTS, []);
  const { executeRequest: fetchInventoryTransfer } = useRequest(
    apiPaths.UPDATE_INVENTORY_TRANSFER,
    [],
  );

  const [message, setMessage] = useState('');
  const INVENTORY_EMPTY_MESSAGE =
    'It appears that you don’t have any inventory. Create an inbound order to add stock to the warehouse';

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (params.orgId && organization === null) {
        try {
          setLoading(true);

          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };

          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          if (organizationsResponse.success && organizationsResponse.data) {
            const org = organizationsResponse?.data.find(
              (org) => org.organizationId === params.orgId,
            );
            setOrganization(org);
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrganizations();
  }, [params.orgId, organization]);

  useEffect(() => {
    const getWarehouseLocations = async () => {
      if (warehouse?.warehouseId) {
        const warehouseLocationData = await fetchWarehouseLocations({
          urlParams: {
            warehouseId: warehouse?.warehouseId,
          },
          queryParams: {},
        });

        if (warehouseLocationData.success) {
          setLocation(warehouseLocationData.data.find((l) => l.locationType === 'storage'));
          setDamagedLocation(warehouseLocationData.data.find((l) => l.locationType === 'damage'));
        }
      }
    };

    getWarehouseLocations();
  }, [warehouse]);

  const fetchInventory = async (page?: number) => {
    const params: any = {
      limit: 50,
    };
    if (!page) {
      setProducts([]);
    }
    currentPageRef.current = page ? page + 1 : 1; // Use 1 as the default page if not provided
    setLoading(true);

    if (selectedMerchants.length > 0) {
      params['filter[merchantId]'] = selectedMerchants.join(',');
    } else if (merchants.length > 0) {
      params['filter[merchantId]'] = merchants.map((m) => m.merchantId).join(',');
    }
    if (selectedMarketplaces.length > 0) {
      params['filter[marketplace]'] = selectedMarketplaces
        .map((marketplace) => marketplace.value)
        .join(',');
    }

    params['filter[warehouseId]'] = warehouses.map((warehouse) => warehouse.warehouseId).join(',');

    if (searchText) {
      params.search = searchText;
    }
    try {
      setLoading(true);

      if ((selectedMerchants.length > 0 || merchants.length > 0) && warehouses.length > 0) {
        const inventoryData = await fetchInventoryCall({
          queryParams: { ...params, page: page || 1, include: 'merchant' }, // Pass the page argument or use 1 as the default
          urlParams: {},
        });
        setHasMore(inventoryData.data.length > 0);

        if (inventoryData.success) {
          setProducts((prevProducts) => {
            const newProducts = inventoryData.data.filter(
              (inventory: InventoryData) =>
                !prevProducts.some(
                  (prevUpload) => prevUpload.inventoryId === inventory.inventoryId,
                ),
            );
            return [...prevProducts, ...newProducts];
          });
        }
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error('Error while fetching inventory:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInventory();
  }, [selectedMerchants, selectedMarketplaces, searchText, merchants, warehouses]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      if (organization?.organizationId) {
        const warehouseData = await fetchWarehousesCall({
          urlParams: {},
          queryParams: {
            'filter[organizationId]': organization.organizationId,
          },
        });

        if (warehouseData.success) {
          setWarehouse(warehouseData.data[0]);
          setWarehouses(warehouseData.data);
        }
      }
    };

    fetchWarehouses();
  }, [organization]);

  useEffect(() => {
    const fetchIntegrations = async () => {
      if (merchants.length > 0) {
        try {
          const integrationResponse = await fetchIntegrationsData({
            queryParams: {
              'filter[merchantId]': merchants.map((merchant) => merchant.merchantId).join(','),
            },
            urlParams: {},
          });

          if (integrationResponse.success) {
            setIntegration(integrationResponse.data[0]);
          }
        } catch (error) {
          console.error('Error while fetching integrations', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchIntegrations();
  }, [merchants]);

  useEffect(() => {
    const enabledMarketplaces = integration?.connections.map(
      (connection) => connection.details.marketplaceId,
    );
    setMarketplaces(
      enabledMarketplaces?.map((marketplace) => {
        return {
          label: MARKETPLACE_DATA.find((data) => data.marketplaceId === marketplace).country,
          value: marketplace,
        };
      }),
    );
  }, [integration]);

  useEffect(() => {
    const fetchMerchants = async () => {
      if (organization?.organizationId) {
        const merchantsData = await fetchMerchantsCall({
          urlParams: {},
          queryParams: {
            'filter[organizationId]': organization?.organizationId,
          },
        });

        if (merchantsData.success) {
          setMerchants(merchantsData.data);
        }
      }
    };

    fetchMerchants();
  }, [organization]);

  useEffect(() => {
    if (selectedInventory === null) {
      setReason('');
      setQuantity(0);
      setOptionIndex(0);
    }
  }, [selectedInventory]);

  const updateInventory = async (action: string) => {
    const inventoryData = await fetchInventoryCall(
      { urlParams: {}, queryParams: {} },
      {
        method: 'POST',
        body: JSON.stringify({
          action: action,
          quantity: quantity,
          sku: selectedInventory?.listing.listingId, //listingId
          warehouseId: location?.warehouseId,
          locationId: location?.locationId,
          reason: reason === '' ? undefined : reason,
        }),
      },
    );

    if (inventoryData.success) {
      fetchInventory();
      setSelectedInventory(null);
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Inventory updated successfully!',
      });
    } else {
      setShowNotification({
        show: true,
        type: 'failure',
        content: inventoryData.message || inventoryData.errors[0]?.message,
      });
    }
  };

  const transferInventory = async (fromLocationId: string, toLocationId: string) => {
    const inventoryData = await fetchInventoryTransfer(
      { urlParams: {}, queryParams: {} },
      {
        method: 'POST',
        body: JSON.stringify({
          quantity: quantity,
          sku: selectedInventory?.listing.listingId,
          warehouseId: location?.warehouseId,
          fromLocationId,
          toLocationId,
          reason: reason === '' ? undefined : reason,
        }),
      },
    );

    if (inventoryData.success) {
      fetchInventory();
      setSelectedInventory(null);
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Inventory updated successfully!',
      });
    } else {
      setShowNotification({
        show: true,
        type: 'failure',
        content: inventoryData.message || inventoryData.errors[0]?.message,
      });
    }
  };

  const renderUploadStage = () => {
    switch (uploadStage) {
      case UploadStage.UploadFile:
        return (
          <UploadFile
            setS3Url={setS3Url}
            setFileName={setFileName}
            selectedFile={selectedFile}
            setUploading={setUploading}
            setFileHeaders={setFileHeaders}
            setUploadStage={setUploadStage}
            setMappedFields={setMappedFields}
            setSelectedFile={setSelectedFile}
            warehouse={warehouse}
            s3Url={s3Url}
            setOpenNewUploadSlideOver={setOpenNewUploadSlideOver}
            setTotalEntries={setTotalEntries}
            authenticatedFetch={authenticatedFetch}
            uploading={uploading}
          />
        );
      case UploadStage.MapFields:
        return (
          <MapFields
            selectedFile={selectedFile}
            fileHeaders={fileHeaders}
            mappedFields={mappedFields}
            setMappedFields={setMappedFields}
            setUploadStage={setUploadStage}
          />
        );
      case UploadStage.Confirmation:
        return (
          <Confirmation
            setUploadStage={setUploadStage}
            selectedFile={selectedFile}
            totalEntries={totalEntries}
            mappedFields={mappedFields}
            warehouse={warehouse}
            fileName={fileName}
            setOpenNewUploadSlideOver={setOpenNewUploadSlideOver}
            setSelectedFile={setSelectedFile}
            setMappedFields={setMappedFields}
            setUploading={setUploading}
            setFileHeaders={setFileHeaders}
            setTotalEntries={setTotalEntries}
            fetchInventory={fetchInventory}
            authenticatedFetch={authenticatedFetch}
          />
        );
      default:
        return null;
    }
  };

  const renderPastUploadsStage = () => {
    if (selectedUpload) {
      return (
        <div>
          <UploadDetails upload={selectedUpload} onClose={() => setSelectedUpload(null)} />
        </div>
      );
    } else {
      return (
        <PastUploads
          warehouse={warehouse}
          authenticatedFetch={authenticatedFetch}
          setOpenPastUploadsSlideOver={setOpenPastUploadsSlideOver}
        />
      );
    }
  };

  const [optionIndex, setOptionIndex] = useState(0);
  enum AdjustOption {
    AddToFulfillable = 'Add to fulfillable',
    DeductFromFulfillable = 'Deduct from fulfillable',
    MoveFromFulfillableToUnfulfillable = 'Move from fulfillable to unfulfillable',
    MoveFromUnfulfillableToFulfillable = 'Move from unfulfillable to fulfillable',
  }

  const adjustOptions = [
    {
      text: AdjustOption.AddToFulfillable,
      action: () => updateInventory('add'),
    },
    {
      text: AdjustOption.DeductFromFulfillable,
      action: () => updateInventory('remove'),
    },
    {
      text: AdjustOption.MoveFromFulfillableToUnfulfillable,
      action: () => transferInventory(location?.locationId, damagedLocation?.locationId),
    },
    {
      text: AdjustOption.MoveFromUnfulfillableToFulfillable,
      action: () => transferInventory(damagedLocation?.locationId, location?.locationId),
    },
  ];

  useEffect(() => {
    if (products.length === 0 && !inventoryLoading && inventoryCallMade) {
      setMessage(INVENTORY_EMPTY_MESSAGE);
    } else setMessage('');
  }, [products, inventoryLoading, inventoryCallMade]);

  return (
    <div className='p-8'>
      <div className='flex justify-between my-2'>
        <div>
          <h1 className='text-[#030229] text-[24px] font-bold'>Inventory</h1>
          <p className='text-[14px]'>
            Manage the inventory for all products fulfilled from your warehouse
          </p>
        </div>
        {/* <button
          className='bg-hopstack-blue-700  flex gap-2 rounded-lg text-white py-2 px-4 hover:opacity-80'
        >
          <ArrowsUpDownIcon className='w-4 my-auto' />
          <div>Sync Products</div>
        </button> */}

        <Menu as={'div'} className='relative overflow-visible'>
          <div>
            <Menu.Button className=''>
              <Button onClick={() => {}}>Bulk Adjust Inventory</Button>{' '}
            </Menu.Button>
          </div>
          <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <Menu.Item
              as='div'
              className='p-2 hover:bg-gray-100 text-left'
              onClick={() => {
                setOpenNewUploadSlideOver(true);
                setUploadStage(UploadStage.UploadFile);
              }}
            >
              <button className='text-gray-600 hover:text-[#224E73] text-sm'>Upload Files</button>
            </Menu.Item>
            <Menu.Item
              as='div'
              className='p-2 hover:bg-gray-100 text-left'
              onClick={() => {
                setOpenPastUploadsSlideOver(true);
              }}
            >
              <button className='text-gray-600 hover:text-[#224E73] text-sm'>Past Uploads</button>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>

      <div className='flex gap-4 my-4'>
        <InventoryFilter
          merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
          marketplaces={marketplaces as any}
          onApply={(checkedMerchants: any, checkedMarketplaces) => {
            setSelectedMerchants(checkedMerchants);
            setSelectedMarketplaces(checkedMarketplaces);
          }}
          onReset={() => {}}
        />

        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-hopstack-blue-600 sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
              onChange={(value) => setSearchText(value.target.value)}
            />
          </div>
        </div>
      </div>
      {products.length > 0 && (
        <div className='block  overflow-x-scroll whitespace-nowrap shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg '>
          <InfiniteScroll
            className='relative'
            pageStart={0}
            loadMore={() => fetchInventory(currentPageRef.current)}
            hasMore={hasMore}
            loader={
              loading &&
              products.length === 0 && (
                <div className='flex justify-center py-10'>
                  <Spinner />
                </div>
              )
            }
          >
            <table className='divide-y divide-gray-300 w-full'>
              <thead className='bg-gray-50 w-full '>
                <tr>
                  <th
                    scope='col'
                    className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                  >
                    Image
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Identifiers
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Client Name
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Expected Quantity
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Total Quantity
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Allocated
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Unfulfillable Quantity
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Fulfillable Quantity
                  </th>
                  <th
                    scope='col'
                    className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200  bg-white'>
                {products.map((product, index) => (
                  <tr className='relative overflow-hidden' key={index}>
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                      <img
                        className='object-scale-down h-24'
                        src={product?.listing.images[0]}
                        height={100}
                        width={100}
                      />
                    </td>
                    <td className='px-3 py-4 text-sm text-gray-500 max-w-[20rem] text-wrap'>
                      {/*  */}
                      <div className='line-clamp-3 '>{product.listing.name}</div>
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      <a
                        href={`${
                          getMarketplace(product.listing.marketplaceData?.marketplaceId)
                            .marketplaceUrl
                        }/dp/${product.listing.marketplaceData?.asin}`}
                        target='blank'
                        className='hover:underline'
                      >
                        <span className='font-bold'>ASIN: </span>
                        {product.listing.marketplaceData?.asin + '\n'}
                      </a>
                      <br />
                      <span className='font-bold'>FNSKU: </span>
                      {product.listing.marketplaceData?.fnSku}

                      {product.listing.marketplaceData.marketplaceId && (
                        <div>
                          <span className='font-bold'>Marketplace: </span>
                          {getMarketplace(product.listing.marketplaceData?.marketplaceId).country}
                        </div>
                      )}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {product.merchant?.name}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {product?.expectedQuantity || 0}
                    </td>
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {product?.totalQuantity || 0}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {product?.allocatedQuantity || 0}
                    </td>
                    <td className='text-wrap w-48 px-3 py-4 text-sm text-gray-500'>
                      {product?.unfulfillableQuantity || 0}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      {product?.totalQuantity - product?.allocatedQuantity || 0}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                      <Menu as={'div'} className='relative overflow-visible'>
                        <div>
                          <Menu.Button className=''>
                            <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                          </Menu.Button>
                        </div>
                        <Menu.Items className='absolute z-20 overflow-visible bottom-10 p-1 -ml-24 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                          <div>
                            <Menu.Item as='div' className='p-2 text-wrap hover:bg-gray-100'>
                              <button
                                onClick={() => setSelectedInventory(product)}
                                className=' gap-2 w-full'
                              >
                                <div className='text-[12px] text-wrap w-32'>Adjust Inventory</div>
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      )}
      {message && <div>{message}</div>}
      <Modal isOpen={selectedInventory !== null} setIsOpen={() => setSelectedInventory(null)}>
        <div className='w-[700px] text-left'>
          <div className='w-full flex justify-between'>
            <h2>Adjust Inventory</h2>
            <button onClick={() => setSelectedInventory(null)}>
              <XMarkIcon className='w-6 h-6 text-gray-400' />
            </button>
          </div>
          <h2 className='text-left'>
            Manage Inventory for SKU: <b>{selectedInventory?.marketplaceData?.sellerSku}</b>{' '}
          </h2>
          <label className='block text-[15px] font-medium text-left leading-6 mt-4 text-gray-900'>
            Operation
          </label>
          <p className='text-[12px] text-gray-500'>Select the operation to perform</p>
          <select
            value={optionIndex}
            onChange={(e) => setOptionIndex(parseInt(e.target.value))}
            className='block mb-4 mt-1 w-[500px] px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600 sm:text-sm sm:leading-6'
          >
            {adjustOptions?.map((option, index) => {
              return (
                <option value={index} key={index}>
                  {option?.text}
                </option>
              );
            })}
          </select>
          <label className='block text-[15px] font-medium text-left leading-6 mt-4 text-gray-900'>
            Quantity
          </label>
          <p className='text-[12px] text-gray-500'>Enter the quantity for the operation</p>
          <input
            type='number'
            value={quantity}
            min={0}
            pattern='[0-9]*'
            onChange={(e) => {
              setQuantity(parseInt(e.target.value));
            }}
            className='block mb-4 mt-1 w-[500px] px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
          />

          <label className='block text-[15px] font-medium text-left leading-6 mt-4 text-gray-900'>
            Reason*
          </label>
          <p className='text-[12px] text-gray-500'>
            Provide a reason for the inventory adjustment (mandatory)
          </p>
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className='block mb-4 mt-1 w-[500px] max-h-xl px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
          />

          <div className='flex gap-2 my-4'>
            <button
              onClick={() => setSelectedInventory(null)}
              className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              Cancel
            </button>
            <button
              onClick={reason.length > 0 ? adjustOptions[optionIndex].action : null}
              disabled={reason.length === 0}
              className={`rounded-md flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600 ${
                reason.length > 0
                  ? 'bg-hopstack-blue-700 hover:bg-hopstack-blue-400'
                  : 'bg-gray-400 cursor-not-allowed'
              }`}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <SlideOver open={openNewUploadSlideOver} setOpen={setOpenNewUploadSlideOver} large={true}>
        {renderUploadStage()}
      </SlideOver>

      <SlideOver open={openPastUploadsSlideOver} setOpen={setOpenPastUploadsSlideOver} large={true}>
        {renderPastUploadsStage()}
      </SlideOver>
    </div>
  );
};

export default Inventory;
