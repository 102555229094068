import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseContext from '../components/common/BaseContext';
import { Organization } from '../components/common/types';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import Onboarding from './Onboarding';
import OrganizationSelector from './OrganizationSelector';
import UserInvitation from './UserInvitation';
import _ from 'lodash';

function LandingPage() {
  //Contexts
  const { setLoading, organization, setOrganization, setOrgRole } = useContext(BaseContext);
  //Navigator
  const navigate = useNavigate();

  // States
  const [isShowOnboarding, setIsShowOnboarding] = useState<boolean>(false);

  // Requests
  const {
    data: invitation,
    executeRequest: fetchInvitations,
    pending: isInvitationFetchPending,
  } = useRequest<UserInvitation[]>('/api/v1/user-invitations/me', []);
  const { data: organizations, executeRequest: fetchOrganizations } = useRequest<Organization[]>(
    apiPaths.GET_ORGANIZATIONS,
    null,
  );
  const { data: warehouses, executeRequest: fetchWarehouse } = useRequest<Organization[]>(
    apiPaths.GET_WAREHOUSES,
    null,
  );

  const { executeRequest: signupUser } = useRequest('/auth/api/v1/signup');
  const { executeRequest: fetchUser } = useRequest('/api/v1/users/me');

  //Handlers
  async function handleOrganizationCheck() {
    try {
      setLoading(true);
      if (!organization) {
        const invitationRequestParams: RequestParams = {
          queryParams: {},
          urlParams: {},
        };
        const invitationResponse = await fetchInvitations(invitationRequestParams);
        if (invitationResponse && invitationResponse.data) {
          const organizationRequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          await fetchOrganizations(organizationRequestParams);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleOrganizationCheck();
  }, [organization]);

  useEffect(() => {
    if (organizations && organizations.length === 1) {
      const organization = organizations[0];
      if (organization?.status === 'active') {
        fetchWarehouse({
          queryParams: {
            'filter[organizationId]': organization.organizationId,
          },
          urlParams: {},
        });
      }

      setOrganization(organization);
      if (organization.subscription && organization.subscription.status === 'active') {
        navigate(`/${organization.organizationId}`);
      }
    }
  }, [organizations]);

  useEffect(() => {
    // Check if organizations data has been fetched
    if (_.isNull(organizations) && !organization) {
      // Organizations data is still being fetched, don't show onboarding yet
      setIsShowOnboarding(false);
      return;
    }

    // If organizations is empty, show onboarding
    if (_.isEmpty(organizations)) {
      setIsShowOnboarding(true);
      return;
    }

    // At this point, we know organizations exist, so check warehouses and organization details
    if (_.isNull(warehouses) || _.isNull(organization)) {
      // Still fetching warehouses or organization details, don't show onboarding yet
      setIsShowOnboarding(false);
      return;
    }

    // Now we have all the data, determine if onboarding should be shown
    const shouldShowOnboarding =
      _.isEmpty(warehouses) ||
      !_.get(organization, 'subscription.status') ||
      _.get(organization, 'subscription.status') !== 'active';

    setIsShowOnboarding(shouldShowOnboarding);
  }, [organizations, warehouses, organization]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (organization) {
        const userResponse = await fetchUser({
          queryParams: {},
          urlParams: {},
        });
        if (userResponse.success)
          setOrgRole(Object.keys(userResponse.data.role[organization?.organizationId])[0] as any);
      }
      setLoading(false);
    })();
  }, [organization]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const res = await signupUser(
        {
          queryParams: {},
          urlParams: {},
        },
        {
          method: 'POST',
        },
      );
      if (res) handleOrganizationCheck();
    })();
    setLoading(false);
  }, []);

  const renderLandingPage = () => {
    if (!isInvitationFetchPending && invitation && invitation.length > 0) {
      return (
        <div className='bg-gray-100 w-full h-full z-[20]'>
          <UserInvitation invitation={invitation[0]} />
        </div>
      );
    } else if (organizations && organizations.length > 1) {
      return <OrganizationSelector organizations={organizations || []} />;
    } else if (isShowOnboarding) {
      return <Onboarding organization={organization} />;
    } else {
      <div></div>;
    }
  };

  return <>{renderLandingPage()}</>;
}

export default LandingPage;
