import React, { useContext, useEffect, useState } from 'react';
import SlideOver from '../common/SlideOver';
import { sellerPartnerRegions } from '../common/utils';
import Cookies from 'universal-cookie';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Client } from '../common/types';
import BaseContext from '../common/BaseContext';
import Dropdown from '../common/Dropdown';

interface AddIntegrationProps {
  isIntegrationsModalOpen: boolean;
  setIsIntegrationsModalOpen: any;
  merchants: Client[];
}

interface CountryInfo {
  country: string;
  countryCode: string;
  marketplaceId: string;
  sellerUrl: string;
}

const AddIntegration = (props: AddIntegrationProps) => {
  const cookies = new Cookies(null, { path: '/' });
  const appBaseUrl = process.env.REACT_APP_BASE_URL;
  const baseAuthPath = `/apps/authorize/consent?application_id=amzn1.sp.solution.2fa956a1-7cb7-48e4-9137-f8f11a3ea610&state=stateexample&redirect_uri=${appBaseUrl}/oauth/sp-api`;
  const sellerCountries: CountryInfo[] = sellerPartnerRegions.map((s) => s.countries)?.flat();

  const [selectedMarketplaces] = useState<number[]>([0]);
  const [client, setClient] = useState(props.merchants[0]);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [authLink, setAuthLink] = useState(`https://sellercentral.amazon.com/${baseAuthPath}`);
  const { organization } = useContext(BaseContext);

  const [country, setCountry] = useState(sellerCountries[0]);
  // const [clients, setClients] = useState([])

  const availableIntegrations = [
    {
      name: 'Amazon Seller Central (FBA)',
      icon: 'https://assets.aboutamazon.com/88/05/0feec6ff47bab443d2c82944bb09/amazon-logo.png',
    },
  ];

  useEffect(() => {
    const integrationData = {
      merchantId: client?.merchantId,
      integrationName: 'Amazon',
      region: 'na',
      organizationId: organization?.organizationId,
      connections: selectedMarketplaces.map(() => {
        return {
          connectionName: country.countryCode,
          status: 'enabled',
          details: {
            marketplaceId: country.marketplaceId,
          },
        };
      }),
    };
    cookies.set('integration_data', integrationData, { path: '/' });
  }, [selectedMarketplaces, country, props.merchants, client, organization]);

  useEffect(() => {
    setAuthLink(`${country.sellerUrl}${baseAuthPath}`);
  }, [selectedMarketplaces, country]);

  return (
    <SlideOver open={props.isIntegrationsModalOpen} setOpen={props.setIsIntegrationsModalOpen}>
      {selectedIntegration ? (
        <div>
          <img width={200} height={200} className='-ml-4' src={selectedIntegration.icon} />

          <label className='block text-[15px] font-medium text-left leading-6 text-gray-900'>
            Select Client
          </label>
          <p className='text-[12px] text-gray-500'>
            Select the client for whom you would like to enable this connection
          </p>
          {/* <select
            value={clientIndex}
            onChange={(e) => setClientIndex(e.target.value)}
            className='block mb-4 mt-2 w-full px-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-hopstack-blue-600  sm:text-sm sm:leading-6'
          >
            {props.merchants?.map((client, index) => {
              return (
                <option value={index} key={index}>
                  {client?.name}
                </option>
              );
            })}
          </select> */}

          <Dropdown<Client>
            items={props.merchants}
            selectedItem={client}
            setSelectedItem={setClient}
            getItemId={(item) => item.merchantId}
            getItemDisplayValue={(item) => item.name}
            placeholder='Select a marketplace'
          />

          <label className='block text-[15px] font-medium text-left leading-6 text-gray-900 mt-6'>
            Select home marketplace
          </label>
          <p className='text-[12px] text-gray-500'>
            Choose the first marketplace that you enrolled in FBA
          </p>
          <Dropdown<CountryInfo>
            items={sellerCountries}
            selectedItem={country}
            setSelectedItem={setCountry}
            getItemId={(item) => item.marketplaceId}
            getItemDisplayValue={(item) => item.country}
            placeholder='Select client'
          />

          {/* <label className='block text-sm font-medium text-left leading-6 text-gray-900'>
            Select Marketplaces
          </label>

          {sellerPartnerRegions
            .find((region) => region.region === sellerPartnerRegions[regionIndex].region)
            ?.countries.map((country, index) => {
              return (
                <div key={index} className='flex gap-2'>
                  <input
                    checked={selectedMarketplaces.includes(index)}
                    onChange={(e) => {
                      if (selectedMarketplaces.includes(index))
                        setSelectedMarketplaces(
                          selectedMarketplaces.filter((mIndex) => mIndex !== index),
                       )
                      else setSelectedMarketplaces([...selectedMarketplaces, index])
                    }}
                    id={`country_${index}`}
                    name='comments'
                    type='checkbox'
                    className='h-4 w-4 my-2 rounded border-gray-300 text-hopstack-blue-600 focus:ring-hopstack-blue-600'
                  />
                  <label htmlFor={`country_${index}`} className='font-medium text-gray-900'>
                    {country.country}
                  </label>
                </div>
              )
            })} */}
          <div className='flex mt-4 gap-2'>
            <button
              onClick={() => setSelectedIntegration(null)}
              className='rounded-md bg-hopstack-blue-500 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
            >
              <ArrowLeftIcon className='w-4 h-4 my-auto' />
              Go Back
            </button>

            {props.merchants.length > 0 ? (
              <a
                href={authLink}
                aria-disabled
                className='rounded-md bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-700/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                Authorize
              </a>
            ) : (
              <button
                aria-disabled
                className='rounded-md bg-gray-400 cursor-not-allowed flex gap-2 p-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
              >
                Cannot authorize without client
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className='flex h-full gap-2 flex-col overflow-y-scroll'>
          <div className='flex w-full justify-between'>
            <div className='text-[18px] font-bold'>Available Connections</div>
            <button onClick={() => props.setIsIntegrationsModalOpen(false)}>
              <XMarkIcon className='text-gray-400 w-6 h-6' />
            </button>
          </div>

          {availableIntegrations.map((integration, index) => {
            return (
              <button
                onClick={() => setSelectedIntegration(integration)}
                key={index}
                className='p-4 w-full h-40 bg-gray-100 mt-2 rounded-xl hover:shadow-md'
              >
                <img width={200} height={200} className='m-auto' src={integration.icon} />
                <div>{integration.name}</div>
              </button>
            );
          })}
        </div>
      )}
    </SlideOver>
  );
};

export default AddIntegration;
