import React from 'react';
import { capitalize } from './utils';

const Badge = ({ color, children }: any) => {
  return color === 'red' ? (
    <div className={`bg-red-400 w-fit px-2 py-1 rounded-full text-white`}>
      {capitalize(children)}
    </div>
  ) : color === 'yellow' ? (
    <div className={`bg-yellow-400 w-fit px-2 py-1 rounded-full text-white`}>
      {capitalize(children)}
    </div>
  ) : color === 'blue' ? (
    <div className={`bg-hopstack-blue-400 w-fit px-2 py-1 rounded-full text-white`}>
      {capitalize(children)}
    </div>
  ) : (
    <div className={`bg-green-400 w-fit px-2 py-1 rounded-full text-white`}>
      {capitalize(children)}
    </div>
  );
};

export default Badge;
