import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom
import DynamicTable, { Column } from '../common/DynamicTable';
import { Organization } from '../common/types';

interface Order {
  inboundOrderId: string;
  inboundOrderDisplayId: string;
  trackingNumber?: string;
  merchant?: {
    name: string;
  };
  products?: Array<{
    expectedQuantity: number;
    receivedQuantity: number;
  }>;
  expectedDeliveryDate: string;
  status: InboundOrderStatus;
}

interface OrdersTableProps {
  orders: Order[];
  handleOrderClick: (order: Order) => void;
  organization: Organization;
}
export enum InboundOrderStatus {
  UNPROCESSED = 'unprocessed',
  PROCESSING = 'processing',
  ON_HOLD = 'on-hold',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PAUSED = 'paused',
}

const getStatusColor = (status: InboundOrderStatus) => {
  switch (status) {
    case InboundOrderStatus.COMPLETED:
      return 'text-green-700 bg-green-100';
    case InboundOrderStatus.PROCESSING:
      return 'text-yellow-700 bg-yellow-100';
    case InboundOrderStatus.ON_HOLD:
      return 'text-gray-700 bg-gray-200';
    case InboundOrderStatus.UNPROCESSED:
      return 'text-gray-700 bg-gray-200';
    case InboundOrderStatus.CANCELLED:
      return 'text-red-700 bg-red-100';
    case InboundOrderStatus.PAUSED:
      return 'text-yellow-700 bg-yellow-100';
    default:
      return '';
  }
};

const InboundOrderTable: React.FC<OrdersTableProps> = ({
  orders,
  handleOrderClick,
  organization,
}) => {
  const columns: Column<Order>[] = [
    {
      header: 'Order ID',
      accessor: (order) => (
        <Link
          className='hover:underline'
          to={`/${organization?.organizationId}/inbound/${order?.inboundOrderId}`}
        >
          {order?.inboundOrderDisplayId}
        </Link>
      ),
      headerClassnamePerColumn: 'py-3.5 pl-4 pr-3 text-left   text-gray-900 sm:pl-6',
    },
    {
      header: 'Tracking Number',
      accessor: (order) => <div className='text-center'>{order?.trackingNumber || '-'}</div>,
      headerClassnamePerColumn: '!text-center',
    },
    {
      header: 'Client Name',
      accessor: (order) => order?.merchant?.name || '',
    },
    {
      header: 'No. of SKUs',
      headerClassnamePerColumn: '!text-center',
      accessor: (order) => <div className='text-center'>{order?.products?.length || 0}</div>,
    },
    {
      header: 'Total Qty.',
      headerClassnamePerColumn: '!text-center',
      accessor: (order) => (
        <div className='text-center'>
          {order?.products?.reduce((acc, product) => acc + product.expectedQuantity, 0) || 0}
        </div>
      ),
    },
    {
      header: 'Received Qty.',
      headerClassnamePerColumn: '!text-center',
      accessor: (order) => (
        <div className='text-center'>
          {order?.products?.reduce((acc, product) => acc + product.receivedQuantity, 0) || 0}
        </div>
      ),
    },
    {
      header: 'Order Date',
      headerClassnamePerColumn: '!text-center',
      accessor: (order) => (
        <div className='text-center'>{new Date(order?.expectedDeliveryDate).toDateString()}</div>
      ),
    },
    {
      header: 'Status',
      headerClassnamePerColumn: '!text-center',
      accessor: (order) => (
        <div className='relative group w-fit mx-auto '>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full   capitalize ${getStatusColor(
              order.status,
            )}`}
          >
            {order?.status}
          </div>
        </div>
      ),
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={orders}
      headerClassname='bg-gray-50'
      rowsClassname={` cursor-pointer transition-colors duration-300`}
      onRowClick={handleOrderClick}
    />
  );
};

export default InboundOrderTable;
