import Button from '../components/common/Button';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseContext from '../components/common/BaseContext';
// import { Integration } from '../components/common/types'
import AddIntegration from '../components/IntegrationManagement/AddIntegration';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/20/solid';
import DynamicTable, { Column } from '../components/common/DynamicTable';
import { MARKETPLACE_DATA } from '../utils/ConnectionConfig';

function Connections() {
  const { organization, setLoading, setOrganization } = useContext(BaseContext);

  // const [integrations, setIntegrations] = useState<Integration[]>([])
  // const [selectedIntegration, setSelectedIntegration] = useState<Integration | null>(null)
  const [merchants, setMerchants] = useState<any[]>([]);
  const [isIntegrationsModalOpen, setIsIntegrationsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState<string>('');

  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { executeRequest: fetchMerchantsData } = useRequest(apiPaths.GET_MERCHANTS, []);
  const {
    data: integrations,
    executeRequest: fetchIntegrationsData,
    // pending: integrationLoading,
    // isRequestExecuted: integrationsCallMade,
  } = useRequest(apiPaths.GET_INTEGRATIONS, []);

  const handleFetchMerchants = async (org) => {
    if (!org) return;

    try {
      const merchantsResponse = await fetchMerchantsData({
        queryParams: { 'filter[organizationId]': org?.organizationId },
        urlParams: {},
      });

      if (merchantsResponse.success) {
        setMerchants(merchantsResponse.data);
      }
    } catch (error) {
      console.error('Error while fetching merchants data:', error);
    }
  };

  const params = useParams();

  //Hooks
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (params.orgId && organization === null) {
        try {
          setLoading(true);

          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };

          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          if (organizationsResponse.success && organizationsResponse.data) {
            const filteredOrg = organizationsResponse?.data.find(
              (org) => org.organizationId === params.orgId,
            );
            setOrganization(filteredOrg);
            handleFetchMerchants(filteredOrg);
          } else {
            location.replace('/');
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      } else if (organization) handleFetchMerchants(organization);
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    fetchIntegrations();
  }, [merchants]);

  const fetchIntegrations = async () => {
    if (merchants.length > 0) {
      try {
        setLoading(true);

        await fetchIntegrationsData({
          queryParams: {
            'filter[merchantId]': merchants.map((merchant) => merchant.merchantId).join(','),
            include: 'merchant',
          },
          urlParams: {},
        });
      } catch (error) {
        console.error('Error while fetching integrations', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Constants
  //
  const columns: Column<any>[] = [
    {
      header: 'Connection Name',
      accessor: (row: any) => (
        <div className=' pl-7  text-start w-fit '>
          <a
            href={`connections/${row.integrationId}`}
            className='text-hopstack-blue-700 underline  '
          >
            {row.integrationName}
          </a>
        </div>
      ),
      headerClassnamePerColumn: 'inline flex pl-10  ',
    },
    {
      header: 'Region',
      accessor: (row) => {
        const regionCode = row.credentials.region?.toUpperCase();
        const marketplaceItem = MARKETPLACE_DATA.find((item) => item.regionCode === regionCode);
        return marketplaceItem ? marketplaceItem.region : 'Unknown';
      },
    },
    {
      header: 'Marketplace',
      accessor: (row) => {
        return (
          <>
            <div className='line-clamp-3'>
              {row.connections
                .flatMap((con) => {
                  const marketplace = MARKETPLACE_DATA.find(
                    (data) => data.marketplaceId === con?.details?.marketplaceId,
                  );
                  return marketplace ? [marketplace.countryCode] : [];
                })
                .join(', ')}
            </div>
          </>
        );
      },
    },

    {
      header: 'Client Name',
      accessor: (row: any) => (
        <div className={`capitalize  rounded-full py-1 w-fit px-2 inline-flex`}>
          {row.merchant.name}
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: (row) => (
        <span
          className={`px-3 py-1 rounded-full  font-medium capitalize ${
            row.status === 'enabled' ? 'bg-[#dcfce7] text-[#166534]' : 'bg-[#f1f5f9] text-[#475569]'
          }`}
        >
          {row.status}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className='w-full h-full p-8'>
        <div className='flex justify-between items-center mb-2 h-12'>
          <div>
            <h1 className='text-[#030229] text-[24px] font-bold'>Connections</h1>
            <p className='text-[14px]'>
              Manage the connections with your marketplaces, shipping carriers and other external
              service providers.
            </p>
          </div>
          <Button onClick={() => setIsIntegrationsModalOpen(true)}>
            <div className='inline-flex justify-between'>
              <PlusIcon height={20} className='my-auto mr-2' />
              Add New Connection
            </div>
          </Button>
        </div>
        <AddIntegration
          merchants={merchants}
          isIntegrationsModalOpen={isIntegrationsModalOpen}
          setIsIntegrationsModalOpen={setIsIntegrationsModalOpen}
        />

        <div className='flex gap-3 py-2 mt-8'>
          <div
            className='p-2 bg-white w-fit rounded-md shadow-lg  border border-gray-300 '
            onClick={() => {}}
          >
            <FunnelIcon className='h-[25px] text-gray-700/85' />
          </div>
          <div className='w-full'>
            <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-lg w-full rounded-md'>
              <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
              </div>
              <input
                id='search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className='block w-full h-10 rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
                placeholder='Search'
                type='search'
                name='search'
              />
            </div>
          </div>
        </div>

        <div>
          <DynamicTable columns={columns} headerClassname='' rowsClassname='' data={integrations} />
        </div>
      </div>
    </>
  );
}

export default Connections;
