import { useState } from 'react';
import useFetch from './useFetch';

export interface RequestParams {
  queryParams: Record<string, string>;
  urlParams: Record<string, string>;
}
// const sleep = async (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const useRequest = <T>(url: string, initialData: T = null) => {
  const authenticatedFetch = useFetch();
  const [pending, setPending] = useState(false);
  const [data, setData] = useState<T>(initialData);
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState({});
  const [isRequestExecuted, setIsRequestExecuted] = useState(false);

  const executeRequest = async (params: RequestParams, options: object = {}) => {
    setPending(true);
    // await sleep(3000);
    try {
      setIsRequestExecuted(false);
      const newQueryParams = new URLSearchParams(params.queryParams);
      let constructedUrl = `${url}?${newQueryParams.toString()}`;

      for (const key in params.urlParams) {
        constructedUrl = constructedUrl.replace(`{${key}}`, params.urlParams[key]);
      }
      const response = await authenticatedFetch(constructedUrl, options);
      setResponse(response);
      const responseData = await response.data;
      setData(responseData);
      setError(null);
      return response;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setPending(false);
      setIsRequestExecuted(true);
    }
  };

  return {
    pending,
    data,
    response,
    error,
    isRequestExecuted,
    executeRequest,
    setData,
  };
};

export default useRequest;
