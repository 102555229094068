import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { getMarketplace } from '../../utils/ConnectionConfig';
import DynamicTable, { Column } from '../common/DynamicTable';

export enum ListingStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived',
}

// Use a more generic type for Product
type Product = {
  images?: string[];
  name?: string;
  status?: string;
  marketplaceData?: {
    sellerSku?: string;
    asin?: string;
    fnSku?: string;
  };
  integrationName?: string;
  merchant?: {
    name?: string;
  };
  isBundle?: boolean;
  bundleItems?: any[]; // Define a more specific type if possible
  [key: string]: any; // Allow for additional properties
};

interface ProductTableProps {
  products: Product[];
  generateFnSKULabel: (product: Product) => void;
  setBundleItem: React.Dispatch<React.SetStateAction<Product>>;
  setDisplayProducts: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedProducts: React.Dispatch<React.SetStateAction<any[]>>;
}

const getStatusColor = (status: ListingStatus) => {
  switch (status) {
    case ListingStatus.ACTIVE:
      return 'text-green-700 bg-green-100';
    case ListingStatus.INACTIVE:
      return 'text-gray-700 bg-gray-100';
    case ListingStatus.ARCHIVED:
      return 'text-red-700 bg-red-100';
    default:
      return '';
  }
};

const ProductTable: React.FC<ProductTableProps> = ({
  products,
  generateFnSKULabel,
  setBundleItem,
  setDisplayProducts,
  setSelectedProducts,
}) => {
  const columns: Column<Product>[] = [
    {
      header: 'Image',
      accessor: (product) => (
        <div className='flex items-center justify-center'>
          {product.images && product.images.length > 0 ? (
            <img
              src={product.images[0]}
              alt={product.name}
              className='h-24 w-24 rounded object-scale-down'
            />
          ) : null}
        </div>
      ),
      headerClassnamePerColumn: 'py-3.5 pl-4 pr-3 text-left text-md text-gray-900 sm:pl-6',
    },
    {
      header: 'Name',
      accessor: (product) => (
        <div className='line-clamp-3 text-gray-600 max-w-prose'>
          {product.name} <br />
          {product.isBundle && (
            <div className='bg-purple-200 w-fit px-3 py-1 text-xs rounded text-purple-700'>
              {' '}
              Bundle
            </div>
          )}
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: (product) => (
        <>
          <div
            className={`w-fit py-1 px-2 mt-2 rounded-full capitalize ${getStatusColor(
              product.status as ListingStatus,
            )}`}
          >
            {product.status}
          </div>
          {product.marketplaceData && !product.marketplaceData.sellerSku && (
            <div className='bg-orange-200 w-fit py-1 px-2 mt-2 text-xs truncate capitalize text-orange-700 rounded-full'>
              Missing Seller SKU
            </div>
          )}
        </>
      ),
    },
    {
      header: 'Identifiers',
      accessor: (product) => (
        <div className='max-w-fit'>
          {product.marketplaceData?.asin && (
            <>
              <a
                href={`${
                  getMarketplace(
                    product.integration?.connections.find(
                      (connection: any) =>
                        connection.connectionId === product.integrationConnectionId,
                    ).details.marketplaceId,
                  ).marketplaceUrl
                }/dp/${product.marketplaceData.asin}`}
                target='_blank'
                rel='noopener noreferrer'
                className='hover:underline'
              >
                <span className='font-bold'>ASIN: </span>
                {product.marketplaceData.asin}
              </a>
              <br />
              <div>
                {(() => {
                  const connection = product.integration?.connections.find(
                    (connection: any) =>
                      connection.connectionId === product.integrationConnectionId,
                  );

                  if (connection?.details.marketplaceId) {
                    return (
                      <>
                        <span className='font-bold'>MARKETPLACE: </span>
                        {getMarketplace(connection.details.marketplaceId).countryCode}
                      </>
                    );
                  }

                  return null;
                })()}
              </div>
            </>
          )}
          {product.marketplaceData?.fnSku && (
            <>
              <span className='font-bold'>FNSKU: </span>
              {product.marketplaceData.fnSku}
              <br />
            </>
          )}
          {product.marketplaceData?.sellerSku && (
            <div className='relative group w-[13rem]'>
              <div className='truncate overflow-hidden'>
                <span className='font-bold'>SKU ID: </span>
                {product.marketplaceData.sellerSku}
              </div>
              <div className='absolute left-0 bottom-full mb-2 hidden w-max max-w-xs rounded bg-white p-2 text-sm shadow-lg group-hover:block'>
                {product.marketplaceData.sellerSku}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      header: 'Source',
      accessor: (product) => product.integrationName,
    },
    {
      header: 'Client',
      accessor: (product) => product.merchant?.name,
    },
    {
      header: 'Action',
      accessor: (product) => (
        <Menu
          as='div'
          className={`relative overflow-visible ${
            product.marketplaceData?.sellerSku ? '' : 'hidden'
          }`}
        >
          <div>
            <Menu.Button className=''>
              <EllipsisVerticalIcon className='w-8 h-8 px-1' />
            </Menu.Button>
          </div>
          <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <div>
              <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                <button onClick={() => generateFnSKULabel(product)} className='flex gap-2 w-full'>
                  <div className='text-[12px]'>Generate FNSKU label</div>
                </button>
              </Menu.Item>
            </div>
            <div>
              <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                <button
                  onClick={() => {
                    setBundleItem(product);
                    if (product?.isBundle) {
                      setDisplayProducts(
                        product?.bundleItems?.map((item) => ({
                          quantity: item.quantity,
                          product: item.listing,
                        })),
                      );
                      setSelectedProducts(product.bundleItems);
                    }
                  }}
                  className='flex gap-2 w-full'
                >
                  <div className='text-[12px]'>
                    {product?.isBundle ? 'Edit bundle' : 'Mark as Bundle'}
                  </div>
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Menu>
      ),
    },
    // {
    //   header: '',
    //   accessor: (product) => (
    //     <div className='flex flex-col justify-center gap-2'>
    //       {product.isBundle && <Badge color='green'>Bundle</Badge>}
    //     </div>
    //   ),
    // },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={products}
      headerClassname=''
      rowsClassname=' transition-colors duration-300 ease-in-out'
    />
  );
};

export default ProductTable;
