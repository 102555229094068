import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import BaseContext from '../components/common/BaseContext';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Warehouse } from '../components/common/types';
import Modal from '../components/common/Modal';
import { useNavigate } from 'react-router-dom';
import FilterAccordion from '../components/common/FilterAccordion';
import useRequest from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { RequestParams } from '../hooks/useRequest';
import FulfillmentFilter from '../components/Fulfillment/FulfillmentFilter';
import FulfillmentTable from '../components/Fulfillment/FulfillmentTable';
import { debounce } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

const statuses = ['unprocessed', 'exception', 'processing', 'paused', 'completed', 'cancelled'];

const Fulfillment = () => {
  const { setLoading, organization, loading } = useContext(BaseContext);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [selectedMerchants, setSelectedMerchants] = useState([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [orders, setOrders] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const pageRef = useRef(0);
  const fetchedPagesRef = useRef(new Set<number>());

  const [orderSearchText, setOrderSearchText] = useState('');

  const navigate = useNavigate();

  const { executeRequest: fetchOrdersData } = useRequest(`${apiPaths.GET_FULFILLMENTS}`, []);

  const { data: warehouses, executeRequest: fetchWarehousesData } = useRequest<Warehouse[]>(
    `${apiPaths.GET_WAREHOUSES}`,
    [],
  );

  const { data: merchants, executeRequest: fetchMerchantsData } = useRequest(
    `${apiPaths.GET_MERCHANTS}`,
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (organization?.organizationId) {
        try {
          const wareHouseMerchantsParams: RequestParams = {
            queryParams: { 'filter[organizationId]': organization.organizationId },
            urlParams: {},
          };
          await Promise.all([
            fetchWarehousesData(wareHouseMerchantsParams),
            fetchMerchantsData(wareHouseMerchantsParams),
          ]);

          await fetchOrders(0);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [organization]);

  const fetchOrders = useCallback(
    debounce(async (page: number) => {
      if (loading || fetchedPagesRef.current.has(page)) return;
      if (!(merchants.length && warehouses.length)) return;
      try {
        const params: RequestParams = {
          queryParams: {
            limit: '50',
            page: (page + 1).toString(),
          },
          urlParams: {},
        };

        if (selectedMerchants.length > 0) {
          params.queryParams['filter[merchantId]'] = selectedMerchants.join(',');
          params.queryParams.include = 'merchant';
        } else {
          params.queryParams['filter[merchantId]'] = merchants
            .map((merchant) => merchant.merchantId)
            .join(',');
          params.queryParams.include = 'merchant';
        }

        if (selectedWarehouses.length > 0) {
          params.queryParams['filter[warehouseId]'] = selectedWarehouses.join(',');
        } else {
          params.queryParams['filter[warehouseId]'] = warehouses
            .map((warehouse) => warehouse.warehouseId)
            .join(',');
        }

        if (selectedStatuses.length > 0) {
          params.queryParams['filter[status]'] = selectedStatuses.map((i) => i.value).join(',');
        }

        if (orderSearchText.length > 0) {
          params.queryParams.search = orderSearchText;
        }

        const ordersDataResponse = await fetchOrdersData(params);

        if (ordersDataResponse.success) {
          setOrders((prevOrders) => {
            const newOrders = ordersDataResponse.data.filter(
              (order: any) => !prevOrders.some((p) => p.fulfillmentId === order.fulfillmentId),
            );
            return [...prevOrders, ...newOrders];
          });

          setHasMore(ordersDataResponse.data.length > 0);
          fetchedPagesRef.current.add(page);
          pageRef.current = page + 1;
        }

        return ordersDataResponse;
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    }, 300),
    [
      selectedMerchants,
      selectedWarehouses,
      selectedStatuses,
      orderSearchText,
      warehouses,
      merchants,
    ],
  );

  const handleFetchOrderAsync = async () => {
    try {
      setLoading(true);
      fetchedPagesRef.current = new Set<number>();
      await fetchOrders(0);
    } catch (error) {
      console.log('Error fetching orders: ', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (warehouses.length && merchants.length) {
      handleFetchOrderAsync();
    }
  }, [selectedMerchants, selectedStatuses, orderSearchText, merchants, warehouses]);

  const applyFilter = () => {
    setIsFiltersModalOpen(false);
  };

  const handleOrderClick = (order) => {
    navigate(`${order.fulfillmentId}`);
  };

  return (
    <div className='p-8'>
      <div className='flex justify-between my-2'>
        <div>
          <h1 className='text-[#030229] text-[24px] font-bold'>Order Fulfillment</h1>
          <p className='text-[14px]'>
            Manage all orders and inventory getting shipped from your warehouse
          </p>
        </div>
      </div>

      <div className='flex gap-4 my-4'>
        <FulfillmentFilter
          merchants={merchants.map((e) => ({ ...e, label: e.name, value: e.merchantId }))}
          statuses={statuses.map((e) => ({ label: e, value: e }))}
          onApply={(checkedMerchants: any, checkedStatus) => {
            setSelectedMerchants(checkedMerchants);
            setSelectedStatuses(checkedStatus);
          }}
          onReset={() => {}}
        />
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              value={orderSearchText}
              onChange={(e) => setOrderSearchText(e.target.value)}
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:outline-none sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>

      <div className=' shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <InfiniteScroll
          className='relative max-h-screen'
          pageStart={0}
          loadMore={() => fetchOrders(pageRef.current)}
          hasMore={hasMore}
          loader={
            loading && orders.length === 0 && <div className='flex justify-center py-10'></div>
          }
        >
          <FulfillmentTable
            orders={orders}
            organization={organization}
            handleOrderClick={handleOrderClick}
          />{' '}
        </InfiniteScroll>
      </div>

      <Modal isOpen={isFiltersModalOpen} setIsOpen={setIsFiltersModalOpen}>
        <div className='w-[500px]'>
          <h2 className='text-left text-[20px]'>Filters</h2>
          <FilterAccordion
            title='Select Clients'
            options={merchants}
            onChange={(selectedId: number) => {
              if (selectedMerchants.includes(selectedId))
                setSelectedMerchants(
                  selectedMerchants.filter((merchantId) => merchantId !== selectedId),
                );
              else setSelectedMerchants([...selectedMerchants, selectedId]);
            }}
            checked={(selectedId) => selectedMerchants.includes(selectedId)}
            uniqueIndex={'merchantId'}
          />

          <FilterAccordion
            title='Select Warehouses'
            options={warehouses}
            onChange={(selectedId: number) => {
              if (selectedWarehouses.includes(selectedId))
                setSelectedWarehouses(
                  selectedWarehouses.filter((merchantId) => merchantId !== selectedId),
                );
              else setSelectedWarehouses([...selectedWarehouses, selectedId]);
            }}
            checked={(selectedId) => selectedWarehouses.includes(selectedId)}
            uniqueIndex={'warehouseId'}
          />

          <button
            onClick={() => applyFilter()}
            className='rounded-md my-4 bg-hopstack-blue-700 flex gap-2 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
          >
            Apply
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Fulfillment;
