import React, { useState } from 'react';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import FilterPanel from '../common/FilterPanel';
import CheckboxFilter from '../common/CheckboxFilter';

type Option = {
  label: string;
  value: string;
  [key: string]: any; // Allows for additional properties
};

interface InventoryFilterProps {
  merchants: Option[];
  marketplaces: Option[];
  onApply: (merchants: Option[], marketplaces: Option[]) => void;
  onReset: () => void;
}

const InventoryFilter: React.FC<InventoryFilterProps> = ({
  merchants,
  marketplaces,
  onApply,
  onReset,
}) => {
  const [selectedMerchants, setSelectedMerchants] = useState<Option[]>([]);
  const [selectedMarketplaces, setSelectedMarketplace] = useState<Option[]>([]);

  return (
    <Menu as='div' className='relative overflow-visible'>
      {({ close }) => (
        <>
          <MenuButton className='relative h-10 w-10 rounded-md border border-gray-300 p-1 text-gray-400 hover:text-gray-800 shadow-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
            <FunnelIcon className='h-6 w-6 m-auto' aria-hidden='true' />
          </MenuButton>

          <MenuItems className='absolute w-[300px] z-50 overflow-visible top-12 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
            <FilterPanel
              onApplyFilters={() => {
                onApply(selectedMerchants, selectedMarketplaces);
                close();
              }}
              onCancel={() => {
                close();
              }}
              handleResetFilters={() => {
                setSelectedMerchants([]);

                setSelectedMarketplace([]);
                onReset();
              }}
              classNames={{
                panel: 'p-4',
                header: 'mb-4',
                title: 'text-[20px]',
                clearButton: 'text-red-600 hover:opacity-60 text-[14px]',
                cancelButton:
                  'rounded-md bg-white text-hopstack-blue-700 border border-hopstack-blue-700 px-8 py-2 text-sm font-semibold shadow-sm hover:bg-gray-50',
                confirmButton:
                  'rounded-md bg-hopstack-blue-700 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400',
              }}
            >
              {merchants?.length > 0 && (
                <CheckboxFilter
                  label='Select Clients'
                  options={merchants}
                  selectedOptions={selectedMerchants}
                  onChange={setSelectedMerchants}
                />
              )}

              {marketplaces?.length > 0 && (
                <CheckboxFilter
                  label='Select Marketplaces'
                  options={marketplaces}
                  selectedOptions={selectedMarketplaces}
                  onChange={setSelectedMarketplace}
                />
              )}
            </FilterPanel>
          </MenuItems>
        </>
      )}
    </Menu>
  );
};

export default InventoryFilter;
