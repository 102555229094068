import React from 'react';
import { getMarketplace } from '../../utils/ConnectionConfig';
import DynamicTable, { Column } from '../common/DynamicTable';
import { Product } from '../common/types';

interface ProductDetailsTableProps {
  order: {
    products: (Product & { receivedQuantity: string })[];
  };
}

const ProductDetailsTable: React.FC<ProductDetailsTableProps> = ({ order }) => {
  const columns: Column<Product & { receivedQuantity: string }>[] = [
    {
      header: 'Image',
      accessor: (product) =>
        product.images && product.images.length > 0 ? (
          <img
            className='rounded-md object-scale-down h-20'
            src={product.images[0]}
            height={100}
            width={100}
            alt={product.name}
          />
        ) : null,
      headerClassnamePerColumn:
        'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      header: 'Product Name',
      accessor: (product) => <div className=' text-gray-600 '>{product.name}</div>,
      headerClassnamePerColumn:
        'py-3.5 pl-4 pr-3 max-w-md text-left text-sm font-semibold text-gray-900 sm:pl-6',
    },
    {
      header: 'Identifiers',
      accessor: (product) => (
        <div className='w-48 text-wrap'>
          <a
            href={`${
              getMarketplace(product.marketplaceData?.marketplaceId).marketplaceUrl
            }/dp/${product.marketplaceData?.asin}`}
            target='_blank'
            rel='noopener noreferrer'
            className='hover:underline'
          >
            <span className='font-bold'>ASIN: </span>
            {product.marketplaceData?.asin}
          </a>
          <br />
          <span className='font-bold'>FNSKU: </span>
          {product.marketplaceData?.fnSku}
          <br />
          <span className='font-bold'>Seller SKU: </span>
          {product.marketplaceData?.sellerSku}
        </div>
      ),
    },
    {
      header: 'Expected Qty.',
      accessor: (product) => <div className='text-center'>{product.expectedQuantity || 0}</div>,
      headerClassnamePerColumn: '!text-center w-[14rem]',
    },
    {
      header: 'Received Qty.',
      accessor: (product) => <div className='text-center'>{product.receivedQuantity || 0}</div>,
      headerClassnamePerColumn: '!text-center w-[14rem]',
    },
  ];

  return <DynamicTable columns={columns} data={order?.products || []} />;
};

export default ProductDetailsTable;
