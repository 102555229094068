import React, { useContext, useEffect, useState } from 'react';

import DynamicTable, { Column } from '../components/common/DynamicTable';
import Button from '../components/common/Button';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useNavigate, useParams } from 'react-router-dom';
import useRequest, { RequestParams } from '../hooks/useRequest';
import { apiPaths } from '../utils/ApiPaths';
import { MARKETPLACE_DATA } from '../utils/ConnectionConfig';
import BaseContext from '../components/common/BaseContext';
import { Client } from '../components/common/types';
import AddNewConnection from '../components/Connections/AddConnection';

import { EllipsisVerticalIcon, TrashIcon } from '@heroicons/react/20/solid';
import WarningModal from '../components/Connections/WarningModal';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';

export interface Integration {
  integrationId: string;
  merchantId: string;
  merchant: Client;
  integrationTags: string[];
  integrationName: string;
  connections: Connection[];
  credentials: Credentials;
  status: string;
  isDeleted: boolean;
  deletedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface Connection {
  connectionId: string;
  connectionName: string;
  status: string;
  syncStatus: string;
  syncError: string;
  details: Details;
  _id: string;
  nickname?: string;
}

export interface Details {
  marketplaceId: string;
}

export interface Credentials {
  region: string;
  refreshToken: string;
  refreshTokenExpiresIn: number;
  sellingPartnerAppClientId: string;
  sellingPartnerAppClientSecret: string;
  sellerId: string;
}

export enum CONNECTION_STATUS {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

const ConnectionDetails: React.FC = () => {
  //Hooks
  const urlParams = useParams();
  const { organization, setLoading, setOrganization, setShowNotification } =
    useContext(BaseContext);
  const navigate = useNavigate();

  //API Requests
  const { executeRequest: fetchOrganizationsData } = useRequest(apiPaths.GET_ORGANIZATIONS, []);
  const { data: integration, executeRequest: fetchIntegration } = useRequest<Integration>(
    apiPaths.GET_INTEGRATIONS + '/{integrationId}',
  );
  const { executeRequest: workflowStatusRequest } = useRequest(
    `${apiPaths.LABEL_GENERATION_WORKFLOW_STATUS}`,
    {},
  );

  const { executeRequest: syncIntegrationRequest } = useRequest<Integration>(
    apiPaths.GET_INTEGRATIONS + '/sync',
  );

  const { executeRequest: updateConnectionStatus } = useRequest(
    apiPaths.UPDATE_INTEGRATIONS + `/connections/{integrationConnectionId}/status`,
    {},
  );

  const productSyncStatus = JSON.parse(localStorage.getItem('productSyncStatus') || '{}');

  //States
  const [isShowAddConnectionPanel, setIsShowAddConnectionPanel] = useState<boolean>(false);
  const [isProductSyncing, setIsProductSyncing] = useState<{
    [key: string]: { syncing: boolean; workflowId: string };
  }>(productSyncStatus);
  const [filteredMarketplaces, setFilteredMarketplaces] = useState([]);
  const [isShowDeleteConnectionConfirmation, setIsShowDeleteConnectionConfirmation] =
    useState<boolean>(false);

  //Constants
  const DEFAULT_REFRESH_TIME_IN_MS = 1000;
  const columns: Column<Connection>[] = [
    {
      header: 'Connection Name',
      accessor: (row) => row?.nickname || row.connectionName,
    },
    {
      header: 'Country',
      accessor: (row) =>
        MARKETPLACE_DATA.find((item) => item.marketplaceId === row.details.marketplaceId)?.country,
    },
    {
      header: 'Status',
      accessor: (row) => (
        <span
          className={`px-2 py-1 rounded-full text-sm font-medium capitalize ${
            row.status === 'enabled' ? 'bg-[#dcfce7] text-[#166534]' : 'bg-[#f1f5f9] text-[#475569]'
          }`}
        >
          {isProductSyncing[row.connectionId]?.syncing ? 'Syncing' : row.status}
        </span>
      ),
    },
    {
      header: 'Action',
      accessor: (row) => (
        <div>
          <Menu as={'div'} className='relative overflow-visible'>
            <MenuButton>
              <div>
                <EllipsisVerticalIcon className='w-6 h-6 text-gray-600' />
              </div>
            </MenuButton>

            <MenuItems className={' bg-white fixed rounded-lg shadow-lg z-50 '}>
              <MenuItem
                as='div'
                className={`p-2 rounded  cursor-pointer hover:bg-gray-100 ${
                  isProductSyncing[row.connectionId]?.syncing &&
                  '!cursor-not-allowed text-gray-400 hover:bg-white'
                }`}
              >
                <div
                  onClick={() =>
                    syncIntegration({
                      integrationId: integration.integrationId,
                      integrationConnectionId: row.connectionId,
                    })
                  }
                >
                  Sync Integration
                </div>
              </MenuItem>
              <MenuItem
                as='div'
                className={`p-2 rounded ${
                  row.status === 'enabled' ? 'text-red-700 ' : 'text-green-700 '
                } cursor-pointer hover:bg-gray-100`}
              >
                <div onClick={() => toggleConnectionStatus(integration.connections.indexOf(row))}>
                  {row.status === 'enabled' ? 'Disable' : 'Enable'} Connection
                </div>
              </MenuItem>
            </MenuItems>
          </Menu>
        </div>
      ),
    },
  ];

  //Functions
  async function fetchMerchantIntegration(urlParams: any) {
    const params: RequestParams = {
      queryParams: {
        include: 'merchant',
      },
      urlParams: {
        integrationId: urlParams.integrationId,
      },
    };
    try {
      setLoading(true);
      await fetchIntegration(params);
    } finally {
      setLoading(false);
    }
  }

  async function checkWorkflowStatus(workflowId: string, connectionId: string) {
    try {
      const workflowStatusRequestParams: RequestParams = { queryParams: {}, urlParams: {} };
      const workflowStatusResponse = await workflowStatusRequest(workflowStatusRequestParams, {
        method: 'POST',
        body: JSON.stringify({
          workflowId,
        }),
      });
      if (workflowStatusResponse.success) {
        if (workflowStatusResponse.data.status === 'COMPLETED') {
          setIsProductSyncing((prev) => {
            return {
              ...prev,
              [connectionId]: {
                syncing: false,
                workflowId: '',
              },
            };
          });
          localStorage.setItem(
            'productSyncStatus',
            JSON.stringify({
              ...isProductSyncing,
              [connectionId]: {
                syncing: false,
                workflowId: '',
              },
            }),
          );

          setShowNotification({
            show: true,
            type: 'success',
            content: 'Products Synced!',
          });
        } else if (workflowStatusResponse.data.status === 'FAILED') {
          setIsProductSyncing((prev) => {
            return {
              ...prev,
              [connectionId]: {
                syncing: false,
                workflowId: '',
              },
            };
          });
          localStorage.setItem(
            'productSyncStatus',
            JSON.stringify({
              ...isProductSyncing,
              [connectionId]: {
                syncing: false,
                workflowId: '',
              },
            }),
          );
          setLoading(false);
          setShowNotification({
            show: true,
            type: 'failure',
            content: 'Unable to Sync Products',
          });
        } else {
          setTimeout(() => {
            checkWorkflowStatus(workflowId, connectionId);
          }, DEFAULT_REFRESH_TIME_IN_MS);
        }
      } else {
        setIsProductSyncing((prev) => {
          return {
            ...prev,
            [connectionId]: {
              syncing: false,
              workflowId: '',
            },
          };
        });
        localStorage.setItem(
          'productSyncStatus',
          JSON.stringify({
            ...isProductSyncing,
            [connectionId]: {
              syncing: false,
              workflowId: '',
            },
          }),
        );
        setShowNotification({
          show: true,
          type: 'failure',
          content: 'Unable to Sync Products',
        });
      }
    } catch (error) {
      console.log('Error checking workflow status', error);
    }
  }
  async function syncIntegration(integrationData: {
    integrationId: string;
    integrationConnectionId: string;
  }) {
    if (isProductSyncing[integrationData.integrationConnectionId]?.syncing) {
      return;
    }
    const syncIntegrationResponse = await syncIntegrationRequest(
      {
        queryParams: {},
        urlParams: {},
      },
      {
        method: 'POST',
        body: JSON.stringify({
          integrationId: integrationData.integrationId,
          integrationConnectionId: integrationData.integrationConnectionId,
        }),
      },
    );

    if (syncIntegrationResponse.success) {
      localStorage.setItem(
        'productSyncStatus',
        JSON.stringify({
          ...isProductSyncing,
          [integrationData.integrationConnectionId]: {
            syncing: true,
            workflowId: syncIntegrationResponse.data.workflowId,
          },
        }),
      );
      setIsProductSyncing((prev) => {
        return {
          ...prev,
          [integrationData.integrationConnectionId]: {
            syncing: true,
            workflowId: syncIntegrationResponse.data.workflowId,
          },
        };
      });
      checkWorkflowStatus(
        syncIntegrationResponse.data.workflowId,
        integrationData.integrationConnectionId,
      );
      setShowNotification({
        show: true,
        type: 'success',
        content: 'Integration Sync started.',
      });
    }
  }

  //Effects
  useEffect(() => {
    const fetchOrganizations = async () => {
      if (urlParams.orgId && organization === null) {
        try {
          setLoading(true);

          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };

          const organizationsResponse = await fetchOrganizationsData(organizationParams);

          if (organizationsResponse.success && organizationsResponse.data) {
            const filteredOrg = organizationsResponse?.data.find(
              (org) => org.organizationId === urlParams.orgId,
            );
            setOrganization(filteredOrg);
          } else {
            location.replace('/');
          }
        } catch (error) {
          console.error('Error fetching organizations:', error);
          location.replace('/');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrganizations();
  }, []);

  useEffect(() => {
    fetchMerchantIntegration(urlParams);
  }, []);

  useEffect(() => {
    Object.keys(isProductSyncing).map((connectionId) => {
      if (isProductSyncing[connectionId]?.syncing) {
        checkWorkflowStatus(isProductSyncing[connectionId].workflowId, connectionId);
      }
    });
  }, [isProductSyncing]);

  useEffect(() => {
    if (integration && integration.connections) {
      const existingMarketplace = integration.connections.map((item) => {
        return item.details.marketplaceId;
      });
      const marketplaces = MARKETPLACE_DATA.filter(
        (item) =>
          item.regionCode === integration.credentials.region?.toUpperCase() &&
          !existingMarketplace.includes(item.marketplaceId),
      ).map((item) => {
        return {
          ...item,
          id: item.marketplaceId,
          name: item.country,
        };
      });
      setFilteredMarketplaces(marketplaces);
    }
  }, [integration]);

  //Handlers

  // Add new connection
  const handleAddConnection = () => {
    setIsShowAddConnectionPanel(!isShowAddConnectionPanel);
    fetchMerchantIntegration(urlParams);
  };

  //Toggle connectin status
  const toggleConnectionStatus = async (index: number) => {
    const connection = integration.connections[index];
    let updatedStatus: CONNECTION_STATUS;
    if (connection.status === CONNECTION_STATUS.ENABLED) {
      updatedStatus = CONNECTION_STATUS.DISABLED;
    } else {
      updatedStatus = CONNECTION_STATUS.ENABLED;
    }
    const params: RequestParams = {
      queryParams: {},
      urlParams: {
        integrationId: integration.integrationId,
        integrationConnectionId: connection.connectionId,
      },
    };
    setLoading(true);
    await updateConnectionStatus(params, {
      method: 'PATCH',
      body: JSON.stringify({
        status: updatedStatus,
      }),
    });
    setLoading(false);
    fetchMerchantIntegration(urlParams);
  };

  const handleDeleteConnection = async () => {
    setLoading(true);
    const params: RequestParams = {
      queryParams: {},
      urlParams: {
        integrationId: urlParams.integrationId,
      },
    };
    await fetchIntegration(params, {
      method: 'DELETE',
    });
    filteredMarketplaces;
    setLoading(false);
    navigate(`/${urlParams.orgId}/connections`);
  };

  const handleConnectionNavigation = () => {
    navigate(`/${urlParams.orgId}/connections`);
  };

  return (
    <div className='p-6 '>
      <div className='flex justify-between items-center mb-4'>
        <h1 className='text-2xl font-semibold text-[#1e293b]'>
          <span onClick={handleConnectionNavigation} className={'hover:underline cursor-pointer'}>
            {' '}
            Connections{' '}
          </span>{' '}
          &gt; <span className='text-[#64748b]'>{integration?.integrationName}</span>
        </h1>
        <div className='flex items-center whitespace-nowrap space-x-4'>
          {/* <Menu as={'div'} className='relative overflow-visible'>
            <MenuButton>
              <div>
                <EllipsisVerticalIcon className='w-6 h-6 text-[#64748b]' />
              </div>
            </MenuButton>

            <MenuItems className={'absolute rounded-lg shadow-lg '}>
              <MenuItem as='div' className='p-2 rounded text-red-700 '>

              </MenuItem>
            </MenuItems>
          </Menu> */}
          <Button
            onClick={() => {
              setIsShowDeleteConnectionConfirmation(true);
            }}
            className='flex gap-1 w-full place-items-center bg-red-700'
          >
            <TrashIcon height={20} />
            <div className=''>Delete Connection </div>
          </Button>
          {/* <BellIcon className='w-6 h-6 text-[#64748b]' /> */}
          <Button onClick={handleAddConnection}>
            <div className='inline-flex justify-between'>
              <PlusIcon height={20} className='my-auto mr-2' />
              Add New Marketplace
            </div>
          </Button>
        </div>
      </div>
      <p className='text-[#64748b] mb-6 text-sm'>
        Manage the connections with your marketplaces, shipping carriers and other external service
        providers.
      </p>
      <div className='grid grid-cols-5 gap-4 mb-6  '>
        <div>
          <h2 className='text-sm font-medium text-gray-500'>Provider</h2>
          <p className='text-gray-900'>{integration?.integrationName}</p>
        </div>
        <div>
          <h2 className='text-sm font-medium text-gray-500 '>Region</h2>
          <p className='text-gray-900'>
            {
              MARKETPLACE_DATA.find(
                (item) => item.regionCode === integration?.credentials.region?.toUpperCase(),
              )?.region
            }
          </p>
        </div>
        <div>
          <h2 className='text-sm font-medium text-gray-500'>Seller ID</h2>
          <p className='text-gray-900'>{integration?.credentials.sellerId}</p>
        </div>
        <div>
          <h2 className='text-sm font-medium text-gray-500 '>Client Name</h2>
          <p className='text-gray-900 w-fit'>{integration?.merchant.name}</p>
        </div>
      </div>

      <DynamicTable
        columns={columns}
        data={integration?.connections || []}
        headerClassname='text-sm font-bold'
        rowsClassname='text-[#1e293b]'
      />

      <AddNewConnection
        isOpen={isShowAddConnectionPanel}
        onClose={handleAddConnection}
        marketplaces={filteredMarketplaces}
        integration={integration}
      />

      <WarningModal
        isOpen={isShowDeleteConnectionConfirmation}
        onClose={() => setIsShowDeleteConnectionConfirmation(!isShowDeleteConnectionConfirmation)}
        onDelete={handleDeleteConnection}
      />
    </div>
  );
};

export default ConnectionDetails;
