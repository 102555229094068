import React from 'react';
import DynamicTable, { Column } from '../common/DynamicTable';

interface Order {
  outboundOrderId: string;
  merchantId: string;
  lineItems?: Array<{ quantity: number }>;
  status: OutBoundOrderStatus; // Add other possible statuses if needed
}

interface Merchant {
  merchantId: string;
  name: string;
}

interface OrdersTableProps {
  orders: Order[];
  merchants: Merchant[];
}

export enum OutBoundOrderStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  CREATED = 'created',
  COMPLETED = 'completed',
}

const getStatusColor = (status: OutBoundOrderStatus) => {
  switch (status) {
    case OutBoundOrderStatus.COMPLETED:
      return 'text-green-700 bg-green-100';
    case OutBoundOrderStatus.PENDING:
      return 'text-yellow-700 bg-yellow-100';
    case OutBoundOrderStatus.FAILED:
      return 'text-red-700 bg-red-100';
    case OutBoundOrderStatus.CREATED:
      return 'text-gray-700 bg-gray-100';
    default:
      return '';
  }
};

const OrdersTable: React.FC<OrdersTableProps> = ({ orders, merchants }) => {
  const columns: Column<Order>[] = [
    {
      header: 'Order ID',
      accessor: (order) => order.outboundOrderId,
      headerClassnamePerColumn: 'py-3.5 pl-4 pr-3 text-left text-gray-900 sm:pl-6',
    },
    {
      header: 'Client Name',
      accessor: (order) => merchants.find((m) => m.merchantId === order.merchantId)?.name || '',
    },
    {
      header: 'No. of Products',
      accessor: (order) => <div className='pl-10'>{order.lineItems?.length || 0}</div>,
    },
    {
      header: 'Quantity',
      accessor: (order) => (
        <div className='pl-10'>
          {order.lineItems?.reduce((acc, product) => acc + product.quantity, 0) || 0}
        </div>
      ),
    },
    {
      header: 'Status',
      accessor: (order) => (
        <div className='relative group w-fit mx-auto '>
          <div
            className={`py-1 px-3 text-sm mt-2 rounded-full   capitalize ${getStatusColor(
              order.status,
            )}`}
          >
            {order?.status}
          </div>
        </div>
      ),
      headerClassnamePerColumn: '!text-center',
    },
  ];

  return (
    <DynamicTable
      columns={columns}
      data={orders}
      headerClassname='bg-gray-50'
      rowsClassname='divide-y divide-gray-200'
    />
  );
};

export default OrdersTable;
