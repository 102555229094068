import { useState } from 'react';
import { Menu } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { fulfillmentDetailNavigation, newBox } from '../../../pages/FulfillmentDetail';
import _ from 'lodash';

const AddPallets = ({
  pallets,
  setPallets,
  setSelectedNav,
  validateContainers,
}: {
  pallets: any;
  setPallets: any;
  setSelectedNav: any;
  validateContainers: any;
}) => {
  const [palletErrors, setPalletErrors] = useState([]);
  const [palletErrorMessage, setPalletErrorMessage] = useState(null);

  const validatePallets = (pallets) => {
    const errorData = validateContainers(pallets);
    setPalletErrors(errorData.errors);
    return {
      hasError: errorData.hasError,
      message: 'Please fill all the fields in the pallets',
    };
  };

  return (
    <div>
      <h2 className='text-[12px]'>Pallet Details</h2>
      <div className='rounded-xl border mt-1 max-h-[500px]'>
        <table className='min-w-full divide-y divide-gray-300 rounded-md'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                #
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Pallet Dimensions
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Pallet Weight
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Action
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {pallets?.map((pallet, index) => {
              return (
                <tr key={index} className='h-20'>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                    {index + 1}
                  </td>
                  <td className='max-w-48 py-4 pl-4 pr-3 text-sm gap-2 font-medium text-gray-900 sm:pl-6'>
                    <div className='flex gap-2'>
                      <input
                        onChange={(e) =>
                          setPallets(
                            pallets.map((box, bIndex) =>
                              index === bIndex ? { ...box, length: parseInt(e.target.value) } : box,
                            ),
                          )
                        }
                        value={pallet.length}
                        type='number'
                        className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                          palletErrors?.[index]?.length?.hasError ? 'border-red-500' : ''
                        }`}
                        placeholder='L'
                      />
                      <div className='my-auto'>x</div>
                      <input
                        onChange={(e) =>
                          setPallets(
                            pallets.map((box, bIndex) =>
                              index === bIndex ? { ...box, width: parseInt(e.target.value) } : box,
                            ),
                          )
                        }
                        value={pallet.width}
                        type='number'
                        className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                          palletErrors?.[index]?.width?.hasError ? 'border-red-500' : ''
                        }`}
                        // value={p.quantity}
                        placeholder='W'
                      />
                      <div className='my-auto'>x</div>
                      <input
                        onChange={(e) =>
                          setPallets(
                            pallets.map((box, bIndex) =>
                              index === bIndex ? { ...box, height: parseInt(e.target.value) } : box,
                            ),
                          )
                        }
                        value={pallet.height}
                        type='number'
                        className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                          palletErrors?.[index]?.length?.hasError ? 'border-red-500' : ''
                        }`}
                        // value={p.quantity}
                        placeholder='H'
                      />
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    <input
                      onChange={(e) =>
                        setPallets(
                          pallets.map((box, bIndex) =>
                            index === bIndex ? { ...box, weight: parseInt(e.target.value) } : box,
                          ),
                        )
                      }
                      value={pallet.weight}
                      type='number'
                      className={`w-[40px] text-center py-2 border rounded-xl text-black [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ${
                        palletErrors?.[index]?.length?.hasError ? 'border-red-500' : ''
                      }`}
                      // value={p.quantity}
                      placeholder=''
                    />
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500'>
                    <Menu as={'div'} className='relative overflow-visible'>
                      <div>
                        <Menu.Button className=''>
                          <EllipsisVerticalIcon className='w-8 h-8 px-1' />
                        </Menu.Button>
                      </div>
                      <Menu.Items className='absolute z-20 overflow-visible top-10 p-1 -ml-24 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
                        <div>
                          <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                            <button
                              onClick={() =>
                                setPallets([
                                  ...pallets,
                                  { ..._.clone(newBox), name: `Pallet${pallets.length + 1}` },
                                ])
                              }
                              className='flex gap-2 w-full'
                            >
                              <div className='text-[12px]'>Add another pallet</div>
                            </button>
                          </Menu.Item>
                        </div>
                        <div>
                          <Menu.Item as='div' className='p-2 hover:bg-gray-100'>
                            <button
                              onClick={() =>
                                setPallets(pallets.filter((b, bIndex) => bIndex !== index))
                              }
                              className='flex gap-2 w-full'
                            >
                              <div className='text-[12px]'>Delete</div>
                            </button>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Menu>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className='flex gap-2 mt-8'>
        <button
          onClick={() => setSelectedNav(fulfillmentDetailNavigation[0].name)}
          className='rounded-md bg-hopstack-blue-600 flex gap-2 px-4 py-2 h-fit text-sm font-semibold text-white shadow-sm hover:bg-hopstack-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-hopstack-blue-600'
        >
          <ArrowLeftIcon className='w-4 h-4 my-auto' />
          Go Back
        </button>
        <button
          onClick={() => {
            const validateResponse = validatePallets(pallets);

            setPalletErrorMessage(validateResponse.message);
            if (validateResponse.hasError) {
              return;
            }
            setSelectedNav(fulfillmentDetailNavigation[2].name);
          }}
          className='bg-hopstack-blue-700 flex gap-2 rounded-lg text-white py-2 text-sm font-semibold px-4 hover:opacity-80'
        >
          <div className=''>Continue</div>
          <ArrowRightIcon className='w-4 h-4 my-auto' />
        </button>
      </div>
      <div className='text-[12px] text-red-500 mt-2'>*{palletErrorMessage}</div>
    </div>
  );
};

export default AddPallets;
